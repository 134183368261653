import { createTheme, Theme, darken, lighten } from "@mui/material/styles";
import { colors } from "./colors";

export default function createGlobalTheme(isDarkMode: boolean): Theme {
  return createTheme({
    drawerWidth: 280,
    rightPanelWidth: 380,
    maxContentWidth: 768,
    palette: {
      mode: isDarkMode ? "dark" : "light",
      primary: {
        main: colors.blue[500],
      },
      secondary: {
        main: isDarkMode ? colors.grayScale[400] : colors.grayScale[600],
      },
      background: {
        paper: isDarkMode ? colors.grayScale[900] : colors.grayScale[50],
        form: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        input: isDarkMode ? colors.grayScale[800] : colors.grayScale[200],
        card: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        popup: isDarkMode ? colors.grayScale[900] : colors.grayScale.white,
        divider: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        button: isDarkMode ? colors.grayScale[900] : colors.grayScale[100],
        backdrop: isDarkMode ? colors.grayScale[800] : colors.grayScale.white,
        buttonHighlighted: isDarkMode
          ? colors.grayScale[700]
          : darken(colors.grayScale[75], 0.05),
        cardDropShadow: isDarkMode
          ? colors.grayScale[800]
          : colors.grayScale[100],
      },
      text: {
        primary: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        secondary: isDarkMode ? colors.grayScale[200] : colors.grayScale[600],
        placeholder: isDarkMode ? colors.grayScale[400] : colors.grayScale[100],
        tertiary: isDarkMode ? colors.grayScale[100] : colors.grayScale[800],
        purple: colors.blue[500],
      },
      colors: {
        ...colors,
      },
    },
    typography: {
      fontFamily: "'Be Vietnam Pro'",
      h1: {
        fontSize: "2.5rem",
        fontWeight: 900,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: "2.25rem",
        fontWeight: 600,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: "2rem",
        fontWeight: 800,
        lineHeight: 1.2,
      },
      h4: {
        fontSize: "1.2rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h5: {
        fontSize: "1.1rem",
        fontWeight: 700,
        lineHeight: 1.2,
      },
      h6: {
        fontSize: "1.25rem",
        fontWeight: 500,
        lineHeight: 1.2,
      },
      body1: {
        fontSize: "0.8rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      button: {
        textTransform: "none",
        fontSize: "1.1rem",
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  });
}
