import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import userAuthReducer from "../features/users/userAuthSlice";
import userCredentialsSlice from "../features/users/userCredentialsSlice";
import userDetailsSlice from "../features/users/userDetailsSlice";
import errorSlice from "../features/ui/errorSlice";

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    userCredentials: userCredentialsSlice,
    userDetails: userDetailsSlice,
    errorMessenger: errorSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export default store;
