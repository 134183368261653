import { Box, Typography, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../../images/logo-vertical-blue.svg";
import React, { useEffect } from "react";

const ComingSoonPage = () => {
  const theme = useTheme();
  const [width, setWidth] = React.useState(window.innerWidth);
  const gradientBackground =
    "linear-gradient(180deg, #9AB3FA 0%, #BFE2FD 100%)";

  const getImageByScreenSize = () => {
    if (width > 600) {
      return (
        <Box
          sx={{
            width: 500,
            "& svg": { width: "100%", height: "100%" },
          }}
        >
          <Logo />
        </Box>
      );
    } else if (width > 300) {
      return (
        <Box
          sx={{
            width: 300,
            "& svg": { width: "100%", height: "100%" },
          }}
        >
          <Logo />
        </Box>
      );
    }
    return (
      <Box
        sx={{
          width: 200,
          "& svg": { width: "100%", height: "100%" },
        }}
      >
        <Logo />
      </Box>
    );
  };

  // create window size listener
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        background: gradientBackground,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        margin={"24px"}
        marginTop={"48px"}
        marginBottom={"0px"}
      >
        {getImageByScreenSize()}
        <Typography
          variant="h4"
          fontWeight={800}
          textAlign={"center"}
          color={theme.palette.colors.blue[500]}
          sx={{ mb: 10 }}
        >
          {"Synchronize Everything"}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      ></Box>
      <Typography
        variant="body2"
        fontSize={12}
        color={theme.palette.colors.blue[300]}
        textAlign={"center"}
      >
        {`- INTRODUCING - `}
      </Typography>
      <Typography
        variant="h4"
        fontSize={{ xs: "16px", sm: "32px" }}
        fontWeight={600}
        color={theme.palette.colors.blue[800]}
        textAlign={"center"}
        lineHeight={1.6}
        marginLeft={"24px"}
        marginRight={"24px"}
      >
        {`The Missing Protocol`}
      </Typography>
      <Typography
        variant="h4"
        fontSize={{ xs: "16px", sm: "32px" }}
        fontWeight={600}
        color={theme.palette.colors.blue[800]}
        textAlign={"center"}
        lineHeight={1.6}
        marginLeft={"24px"}
        marginRight={"24px"}
        sx={{ mb: 2 }}
      >
        {`of the Internet`}
      </Typography>
      <Typography
        variant="body2"
        color={theme.palette.colors.blue[300]}
        textAlign={"center"}
        lineHeight={1.6}
        marginLeft={"24px"}
        marginRight={"24px"}
        sx={{ mb: 2 }}
      >
        {`Rewards for Noders and Coders coming here soon!`}
      </Typography>

      <Box height={"100%"} />
    </Box>
  );
};

export default ComingSoonPage;
