import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import LandingPage from "../pages/landing/LandingPage";
// import NotFoundPage from "../pages/NotFoundPage";
// import AuthPage from "../pages/auth/AuthPage";
import ComingSoonPage from "../pages/ComingSoonPage";

const PageManager: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ComingSoonPage />} />
      {/*
      <Route path="/" element={<LandingPage />} />
      <Route path="/auth" element={<AuthPage />} /> */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default PageManager;
