import { AxiosError } from "axios";
import store from "./store";
import { setForceLogout } from "../features/users/userAuthSlice";
interface ErrorResponseData {
  message: string;
}

export const handleAxiosError = (error: unknown): string => {
  const e = error as AxiosError<ErrorResponseData>;

  if (e.response) {
    if (e.response.status === 498) {
      store.dispatch(setForceLogout(true));
      return "Invalid Token";
    }

    if (e.response.status === 429) {
      return "Too many requests";
    }

    return e.response.data.message;
  } else {
    // Network error or other unknown errors
    return "An unknown error occurred";
  }
};
