import {
  IGoogleAuthFormData,
  IUser,
  IAuthFormData,
  IUserDetails,
  IUserCredentials,
  IResetPasswordPayload,
} from "../types/user/userTypes";
import API from "./api";

// current user
export const loginGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/users/loginGoogleUser", googleAuthFormData);
export const signUpGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/users/signUpGoogleUser", googleAuthFormData);
export const login = (authFormData: IAuthFormData) =>
  API.post("/users/login", authFormData);
export const signUp = (authFormData: IAuthFormData) =>
  API.post("/users/signup", authFormData);
export const validateToken = () => API.get("/users/validateToken");
export const updateUser = (updatedUser: IUser) =>
  API.put("/users/updateUser", updatedUser);

// user details
export const loadUserDetails = (id: string) =>
  API.post("/users/loadUserDetails", id);
export const updateUserDetails = (userDetails: IUserDetails) =>
  API.put("/users/updateUserDetails", userDetails);

// user credentials
export const loadUserCredentials = (id: string) =>
  API.post("/users/loadUserCredentials", id);
export const updateUserCredentials = (userCredentials: IUserCredentials) =>
  API.put("/users/updateUserCredentials", userCredentials);
export const resendVerificationEmail = (email: string) =>
  API.post("/users/resendVerificationEmail", email);
export const sendResetPasswordEmail = (email: any) =>
  API.post("/users/sendResetPasswordEmail", email);
export const resetPassword = (resetPasswordPayload: IResetPasswordPayload) =>
  API.post("/users/resetPassword", resetPasswordPayload);
